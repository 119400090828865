import React from "react";
import { graphql, PageProps } from "gatsby";

import { TestimonialsPage as seo } from "../../helpers/seo";

import SectionLayout from "../../components/SectionLayout";

const TestimonialsPage: React.FC<PageProps<Queries.TestimonialsPageQuery>> = ({
  data,
}) => (
  <SectionLayout id="why" title={seo.title}>
    {data.allTestimonialsYaml.nodes.map((testimonial) => (
      <div
        key={testimonial.id}
        className="border-2 border-gray-300 hover:border-clhbid-orange rounded-md p-8 mb-8 transition-colors duration-500"
      >
        {testimonial.tagline && (
          <h1 className="text-2xl mb-6">{testimonial.tagline}</h1>
        )}
        <p className="whitespace-pre-line break-words mb-10">
          {testimonial.content}
        </p>
        <div className="font-bold">{testimonial.name}</div>
        <div className="text-sm text-gray-500">
          {testimonial.title}
          {testimonial.title && testimonial.city && " - "}
          {testimonial.city}
        </div>
      </div>
    ))}
  </SectionLayout>
);

export const query = graphql`
  query TestimonialsPage {
    allTestimonialsYaml {
      nodes {
        id
        tagline
        name
        title
        city
        content
      }
    }
  }
`;

export default TestimonialsPage;
